@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lusitana&display=swap');

html {
  background-color: #fffff5;
  box-sizing: border-box;
  font-size: 18px;
  height: 100%;
  width: 100%;
}

body {
  background-color: #fffff5;
  height: 100%;
  margin: 0;
  color: #404040;
  padding: 0;
  width: 100%;
  line-height: 130%;
  animation: color-n-blur 0.85s ease-out;
}

*,
*::before,
*::after {
  /* border: 0.1px solid #a7a7a7; */
  box-sizing: inherit;
  font-family: 'Assistant', sans-serif;
  margin: 0;
  padding: 0;

}

#root {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.App {
  padding: 1rem;
  width: 100%;
}

#flex-container {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 1200px;
}

a {
  color: #393939;
  text-decoration: none;

}

#hi {
  position: relative;
  text-align: center;
  width: 30%;
}

#my-photo {
  border-radius: 50%;
  margin-bottom: 1em;
  width: 150px;
  opacity: 0.9;
}

/* #my-photo:hover {
  cursor: pointer;
} */

/* .my-photo-active {
  box-shadow: 0 0 30px 4px #124485;
}

.my-photo-inactive {
} */

.current-screen {
  animation: blur-in 0.4s ease;
}

#details {
  max-height: 100%;
  width: 60%;
}

#projects {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

#hello-sander {
  font-family: 'Lusitana';
  font-size: 2.7rem;
  margin: 0 0 1rem 0;
  transition: transform 0.2s ease;
  line-height: 125%;

}

#intro {
  margin-bottom: 1rem;
  text-align: center;
  color: #4f4f4f;
}

li {
  display: inline-block;
}

li:not(:last-child) {
  margin-right: 1em;
}

.proj,
#about,
#stack,
#links {
  padding: 0.65rem;
}

.proj img {
  border-radius: 0.6em;
  width: 100%;
  opacity: 0.95;
}



.proj {
  max-width: 33%;
}



.project-name {
  font-size: 1.2rem;
  margin: 0 10px 0 0;
}

.project-name:hover .link-icon {
  opacity: 0.8;
  transform: rotate(45deg);
}

.proj-descr {
  color: #4f4f4f;

}

.header-with-icon {
  align-items: center;
  display: inline-flex;
  max-width: fit-content;
  padding: 0.6em 0;

}

.header-with-icon:hover .link-icon {
  opacity: 0.8;
  transform: rotate(45deg);
}

.link-icon {
  font-size: 0.65em;
  opacity: 0.3;
  transform: translateY(-0.1em);
  transition: all 0.3s ease-in-out;
}

#stack {
  overflow: hidden;
  position: relative;
  user-select: none;
}

#stack:before,
#stack:after {
  bottom: 0;
  content: '';
  position: absolute;
  top: 0;
  width: 50px;
  z-index: 2;
}

#stack:before {
  box-shadow: inset 10px 0 15px 3px #fffff5;
  left: 10px;
}

#stack:after {
  box-shadow: inset -10px 0 15px 3px #fffff5;
  right: 10px;
}

#stack li {
  border: 0.1px solid #dcdcdc;
  border-radius: 0.6em;
  font-size: 0.9rem;
  color: #5d5d5d;
  margin: 0.3em;
  padding: 0.4em 0.6em;
}

nav li:hover {
  cursor: pointer;
}

.selected {
  border-bottom: #dcdcdc 0.1px solid;
  color: #181818;
  padding-bottom: 0.2em;

}

#links {
  font-size: 23px;
  margin: 0 auto;
  text-align: center;
  user-select: none;
}


#link-to {
  padding-bottom: 0.05em;
  border-bottom: #dcdcdc 0.1px solid;
  transition: all 1.5s ease;

}

#link-to:hover {
  padding-bottom: 0.05em;
  border-bottom: #393939 0.1px solid;
}

.icon {
  opacity: 0.65;

}

.icon:hover {
  cursor: pointer;
  opacity: 1;
}

.svg-icon {
  transform: translateY(0.125em);
  width: 23px;

}

svg {
  outline: none;
}

/* #email {
  font-size: 1.31rem;
  transform: translateY(-0.15em);
  outline: none;
} */

@keyframes blur-in {
  0% {

    filter: blur(4px);
  }

  100% {

    filter: none;
  }
}

@keyframes color-n-blur {
  0% {
    filter: blur(4px) grayscale(1);

  }

  100% {

    filter: none;
    transform: none;

  }
}



@media (max-width: 700px) and (orientation: portrait) {
  .App {
    font-size: 93%;
    height: 100%;
    max-width: 700px;
  }

  #flex-container {
    flex-direction: column;
    margin-top: 1em;
    padding: 0.4rem;
  }

  #my-photo {
    margin-bottom: 1em;
    max-width: 150px;
    width: 25vw;
  }

  #hi {
    height: 100%;
    width: 100%;
  }

  #hello-sander {
    font-size: 11vw;
  }

  #intro {
    padding: 0 2em;
  }

  #details {
    width: 100%;
  }

  #projects {
    flex-direction: column;
    margin: 4vw 0 0 0;
  }

  #about {
    margin: 4vw 0 0 0;
    padding-left: 1em;
    padding-right: 1em;
  }

  #stack {
    margin-bottom: 2em;
    padding-bottom: 0;
  }

  .proj {
    max-width: 100%;
    padding: 1em;
  }

  #links {
    font-size: 5vmin;
    margin: 5vw 0 5vw 0;
    padding: 0;
    text-align: center;
  }

  .svg-icon {
    width: 5vmin;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  .App {
    height: 100%;
  }

  #flex-container {
    display: block;
  }

  #hi {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 48%;
    max-height: 95%;
  }

  #my-photo {
    border-radius: 50%;
    margin-bottom: 1em;
    max-width: 100px;
    transition: all 1.5s ease;
    width: 20vw;
  }

  #intro {
    padding: 0 2em;
  }

  #details {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48%;
    padding: 1em;
  }

  #projects {
    flex-direction: column;
    margin: 4vw 0 0 0;
    padding: 0;
    margin-top: 0;
  }

  .proj {
    max-width: 100%;
    /* padding: 1em; */
  }

  #links {
    font-size: 3vw;
    margin: 3vw 0;
    padding: 0;


  }

  .svg-icon {
    width: 3vw;
  }
}